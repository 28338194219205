<template>
  <b-modal
    id="broadcast-modal"
    ref="broadcast-modal"
    body-class="main-scroll-v follow-modal px-8 pt-6 participants-modal d-flex flex-column"
    centered
    hide-footer
    hide-header
    scrollable
    size="lg"
  >
    <h3 class="text-center font-weight-semi-bold text-color-1 mb-0">
      {{ $t("broadcast.broadcastMessage") }}
    </h3>
    <b-form-group
      id="input-group-2"
      :label="$t('broadcast.recipientsLabel')"
      label-for="input-2"
    >
      <v-select v-model="formattedRecipients" multiple disabled>
        <template #selected-option-container="{ option }">
          <div class="vs__selected">{{ option.label }}</div>
        </template>
      </v-select>
    </b-form-group>
    <b-form-group
      id="input-group-2"
      :label="$t('broadcast.messageLabel')"
      label-for="input-2"
    >
      <b-form-textarea
        id="input-2"
        v-model="message"
        maxLength="500"
        class="border px-3 py-5"
        :placeholder="$t('broadcast.messageInputPlaceholder')"
        rows="8"
        max-rows="6"
      >
      </b-form-textarea>
      <small style="font-display: inline-block; float: right; margin-top: 10px;"
        >{{ message.length }} of 500</small
      >
    </b-form-group>
    <b-row align-h="center" class="mt-6" no-gutters>
      <b-col class="d-flex justify-content-center px-3 mt-8" cols="auto">
        <b-button
          class="mx-2 mx-sm-0 main-btn"
          variant="primary"
          @click="sendBroadcastMessage"
        >
          <b-spinner v-if="sendingMessage" class="mr-2" small></b-spinner>
          {{ $t("broadcast.sendMessage") }}
        </b-button>
      </b-col>
      <b-col class="d-flex justify-content-center px-3 mt-8" cols="auto">
        <b-button
          class="text-color-2 mx-2 mx-sm-0 main-btn"
          variant="outline-dark"
          @click="() => $refs['broadcast-modal'].hide()"
        >
          {{ $t("setting.userinformaationsettingModalCancle") }}
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import Api from "@/services/Api";

export default {
  props: ["mentorcastId"],
  data() {
    return {
      sendingMessage: false,
      message: "",
      recipients: []
    };
  },
  computed: {
    formattedRecipients() {
      if (this.recipients.length <= 20) {
        return this.recipients;
      } else {
        const truncatedRecipients = this.recipients.slice(0, 20);
        const limitedOptions = [...truncatedRecipients, "..."];
        return limitedOptions;
      }
    }
  },
  methods: {
    async sendBroadcastMessage() {
      if (!this.message || this.message.trim() === "") {
        this.$alertify.error("Enter message");
        return;
      }

      try {
        this.sendingMessage = true;
        await Api().post(`/chat/broadcast-message`, {
          mentorcast_id: this.mentorcastId,
          message: this.message
        });
        this.message = "";
        this.sendingMessage = false;
        this.$bvModal.hide("broadcast-modal");
        this.$alertify.success("Message sent successfully");
      } catch (errors) {
        this.sendingMessage = false;
        if (errors && errors.response) {
          if (errors.response.statue === 422) {
            for (const e of errors.response.data.errors) {
              e.map(item => this.$alertify.error(item));
            }
          }
        } else {
          console.error(errors);
        }
      }
    },
    setRecipients(recipients) {
      this.recipients = recipients;
    }
  }
};
</script>
