
import Vue from "vue";
import moment from "moment/moment";

export default Vue.extend({
  name: "ContentItem",
  props: {
    content: Object,
    isMentor: Boolean
  },
  data() {
    return {
      processing: false
    };
  },
  computed: {
    formattedDate(): any {
      return moment(this.content.created_at).format("Do MMM, YYYY");
    },
    getContentStatusBg(): any {
      return "bg-white";
    }
  },
  methods: {
    openContentEditModal() {
      if (!this.isMentor) return;
      this.$emit("onEdit", {
        content: this.content,
        name: "edit-content-modal"
      });
    },
    openContentDeleteModal() {
      if (!this.isMentor) return;
      this.$emit("onDelete", {
        content: this.content,
        name: "delete-content-modal"
      });
    },
    openViewContentModal() {
      this.$emit("onView", {
        content: this.content,
        name: "view-content-modal"
      });
    }
  }
});
