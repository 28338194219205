
import Vue from "vue";
import ParticipantDropdown from "@/components/Session/ParticipantDropdown.vue";
// import ChatModal from "@/components/Modals/ChatModal.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "ParticipantView",
  components: {
    ParticipantDropdown
    // ChatModal
  },
  props: {
    user: {
      type: Object
    }
  },
  data() {
    return {
      isOpenedInPopup: false,
      chatUser: {}
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    })
  },
  methods: {
    update(participant: any) {
      this.$emit("update", participant);
    },
    startChat(data: any) {
      this.$router.push({ path: `/conversations/${data.user.id}` });
      // this.chatUser = data.user;
      // this.isOpenedInPopup = true;
      // this.$bvModal.show("mentorcast-chat-modal");
    },
    getRoute(data: any) {
      console.log("data", data);

      if (data.type == "mentor") {
        return `/mentors/${data.uuid}`;
      } else {
        return `/mentorees/${data.uuid}`;
      }
    }
  }
});
