
import Vue from "vue";
import { InformationData } from "@/types/interfaces";
import { mapGetters } from "vuex";
import StripeModal from "@/components/Modals/StripeModal.vue";
import PaypalCheckout from "@/components/Modals/PaypalCheckout.vue";
import moment from "moment";
import ApplyDiscountModal from "@/components/Modals/ApplyDiscountModal.vue";

export default Vue.extend({
  name: "PaymentModal",
  components: {
    PaypalCheckout,
    StripeModal,
    ApplyDiscountModal
  },
  props: {
    booking: {
      type: Object
    },
    seat: {
      type: Object
    },
    isMentorcast: {
      type: Boolean
    },
    isLibraryContent: {
      type: Boolean
    },
    seatData: {
      type: Object
    }
    // shouldSelectSeat: {
    //   type: Boolean,
    //   default: true
    // }
  },
  data() {
    return {
      i_agree: false,
      payBtnLoading: false,
      loading: false,
      selectedPaymentMethod: null as any,
      defaultSeatOption: "standard",
      isSeatSelected: false,
      availableSeatOptions: [],
      seatOptions: new Set() as any,

      data: {
        id: this.booking && this.booking.id,
        type: this.isMentorcast
          ? "mentorcast"
          : this.isLibraryContent
          ? "library-content"
          : "session",
        payment_method_id: "",
        payment_method_type: "",
        //for paypal
        payer_id: "",
        discountable_id: null,
        discounted_price: null
      },
      infoData: [
        {
          name: "recipient",
          label: (this as any).$t("mentorcastHero.PaymentModalRecipientLabel"),
          value: this.booking && this.booking.user && this.booking.user.name
        },
        {
          name: "amount",
          label: (this as any).$t("mentorcastHero.PaymentModalAmountLabel"),
          value: this.booking && this.booking.seat_price + " USD"
        },
        {
          name: "date",
          label: (this as any).$t("mentorcastHero.PaymentModalDateLabel"),
          value: `${this.booking && this.booking.start_date} ${this.booking &&
            this.booking.start_time}`
        }
      ] as InformationData[],
      stripeCards: [
        { label: "Master Card", value: "mastercard", icon: "mastercard" },
        { label: "Visa", value: "visa", icon: "visa" }
      ],
      cardTypeOptions: [
        { label: "Paypal", value: "paypal", icon: "paypal" },
        {
          label: "Credit/Debit Card (via Stripe)",
          value: "stripe",
          icon: "stripe"
        }
      ],
      paypalLoaded: false
    };
  },
  computed: {
    ...mapGetters({
      seats: "mentorcast/getMentorcastSeats",
      paymentMethods: "stripePayment/getPaymentMethods",
      paymentTermsLink: "helper/getPaymentTermsLink",
      platformCurrency: "helper/getDefaultPlatformCurrency"
    }),
    isPayable(): boolean {
      const formattedAmount = String(this.infoData?.[1]?.value);
      if (formattedAmount) {
        const amount = formattedAmount?.split(" ")?.[0];
        return parseInt(amount) > 0;
      }
      return false;
    }
  },
  mounted(): void {
    this.$store.dispatch("stripePayment/GET_PAYMENT_METHODS");
    this.$store.dispatch("helper/GET_DEFAULT_PLATFORM_CURRENCY");

    this.getSeats();

    if (this.isMentorcast) {
      //@ts-ignore
      this.infoData.splice(2, 0, {
        name: "mentorcast",
        label: (this as any).$t("mentorcastHero.PaymentModalMentorcastLabel"),
        value: this.booking.title
      });
    } else if (this.isLibraryContent) {
      //@ts-ignore
      this.infoData[1]["value"] =
        this.booking.price + " " + this.platformCurrency;

      this.infoData.splice(2, 0, {
        name: "content",
        label: (this as any).$t("mentorcastHero.PaymentModalContentLabel"),
        value: this.booking.title
      });

      this.infoData.splice(3, 1);
      this.infoData.splice(4, 1);

      this.selectedPaymentMethod = this.booking.payment_method;
      // this.selectPaymentMethod();
      this.isSeatSelected = true;
    } else {
      //@ts-ignore
      this.isSeatSelected = true;
      this.infoData.splice(2, 0, {
        name: "session",
        label: (this as any).$t("mentorcastHero.PaymentModalSessionLabel1"),
        value: (this as any).$t("mentorcastHero.PaymentModalSessionLabel2")
      });
    }
  },
  methods: {
    selectedSeatOption(option: any) {
      if (option) {
        /*console.log(option , 'selected option type')*/
        const filteredData = this.availableSeatOptions.filter(
          item => item["color"] === option.color
        );
        /*console.log(filteredData[0], 'autp selected option')*/
        this.$emit("reserveSeat", filteredData[0]);
      } else {
        this.$emit("reserveSeat");
      }
    },
    selectSeat(seleted: any = true) {
      this.isSeatSelected = seleted;
    },
    getSeats() {
      this.loading = true;

      this.$store
        .dispatch("mentorcast/GET_MENTORCAST_SEATS", {
          id: this.$route.params.id,
          page: 1,
          all_seats: true
        })
        .then(data => {
          this.loading = false;
          /*console.log(data, 'seats');*/
          const seatsData = data.data;
          this.availableSeatOptions = seatsData.filter(
            (seat: any) => seat.price !== null && seat.available === true
          );
          if (this.availableSeatOptions.length > 0) {
            /*console.log(this.availableSeatOptions, 'this.availableSeatOptions');*/

            this.seatOptions = seatsData.filter((item: any) => {
              if (
                !this.seatOptions.has(item.color) &&
                item.price !== null &&
                item.available == true
              ) {
                this.seatOptions.add(item.color);
                return true;
              }
              return false;
            });
            const seatOptionsArray = Array.from(this.seatOptions);

            seatOptionsArray.sort(
              (a: any, b: any) => parseFloat(a.price) - parseFloat(b.price)
            );

            this.seatOptions = new Set(seatOptionsArray);
            /*console.log(this.seatOptions, 'this.seatOptions');*/
          }
        });
    },
    modalClosed() {
      this.paypalLoaded = false;
      this.data.payment_method_id = "";
      this.i_agree = false;
      this.data.payment_method_type = "";
      this.data.discountable_id = null;
      this.data.discounted_price = null;
      this.selectedPaymentMethod = null;
      this.$emit("hide");
    },
    parseDateTime(date: any) {
      if (date) {
        return moment(date)
          .format("h:mm A, DD/MM/YYYY")
          .toString();
      }
      return "";
    },
    paypalCheckout(data: any) {
      this.data.payment_method_id = data.payment_id;
      this.data.payer_id = data.payer_id;
      this.pay();
    },
    setPaymentMethodType(type: any) {
      this.data.payment_method_type = type;

      if (type == "paypal" && this.i_agree && !this.paypalLoaded) {
        this.loadPaypal();
      }
    },
    setAgree() {
      this.i_agree = !this.i_agree;
      if (
        this.i_agree &&
        this.data.payment_method_type == "paypal" &&
        !this.paypalLoaded
      ) {
        this.loadPaypal();
      }
    },
    loadPaypal() {
      //@ts-ignore
      this.$refs["paypal_checkout"].mount();
      this.paypalLoaded = true;
    },
    onStripeModal() {
      this.$bvModal.show("stripe");
      //@ts-ignore
      this.$refs.stripe.loadStripe();
    },
    pay() {
      this.payBtnLoading = true;

      this.$store
        .dispatch("booking/CREATE_BOOKING", {
          ...this.data,
          ...this.seat
        })
        .then(({ data }) => {
          this.payBtnLoading = false;
          this.selectedPaymentMethod = null;
          this.data.payment_method_id = "";
          this.$bvModal.hide("payment");
          this.$emit("complete", data.id);
        })
        .catch(error => {
          this.payBtnLoading = false;
          if (error.response.status === 422) {
            for (const e of error.response.data.errors) {
              // @ts-ignore
              this.$alertify.error(e);
            }
          }
        });
    },
    getIconType(type: string) {
      if (this.stripeCards.find(i => i.value === type)) {
        //@ts-ignore
        return this.stripeCards.find(i => i.value === type).icon;
      }
      return "paypal";
    },
    // Select payment method
    selectPaymentMethod() {
      const index = this.paymentMethods.findIndex(
        (i: any) => i.id === this.data.payment_method_id
      );
      if (index > -1) {
        this.selectedPaymentMethod = this.paymentMethods[index];
        (this.$refs["select-payment-method-modal"] as Vue & {
          hide: () => boolean;
        }).hide();
      }
    },
    setPrice(price: any) {
      return new Promise<void>(resolve => {
        const index = this.infoData.findIndex((e: any) => e.name == "amount");
        if (index > -1) {
          this.infoData[index].value = price;
        }
        resolve();
      });
    },
    setInfoData(booking: any) {
      return new Promise<void>(resolve => {
        this.data.id = booking.id;

        this.infoData = [
          {
            name: "recipient",
            label: (this as any).$t(
              "mentorcastHero.PaymentModalRecipientLabel"
            ),
            value: booking.user.name
          },
          {
            name: "amount",
            label: (this as any).$t("mentorcastHero.PaymentModalAmountLabel"),
            value: booking.seat_price + ".00 " + this.platformCurrency
          },
          {
            name: "date",
            label: (this as any).$t("mentorcastHero.PaymentModalDateLabel"),
            value: `${booking.start_date} ${booking.start_time}`
          }
        ];
        resolve();
      });
    },
    applyDiscount() {
      this.$bvModal.show("apply-discount");
    },
    onApplyDiscount(data: any) {
      this.data.discountable_id = data.discountable_id;
      this.data.discounted_price = data.save_price;
      this.setPrice(data.new_price).then();
    }
  }
});
