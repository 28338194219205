
import Vue from "vue";
import Socket from "@/services/Socket";
import { mapGetters } from "vuex";
import ChatMessagesLoading from "@/components/Session/ChatMessagesLoading.vue";
import MessageDeliveryIcon from "@/components/MessageDeliveryIcon.vue";

export default Vue.extend({
  name: "MentoringSessionChat",
  components: {
    ChatMessagesLoading,
    MessageDeliveryIcon
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      }
    }
  },
  props: {
    type: {
      type: String
    }
  },
  data() {
    return {
      loading: true,
      data: {
        message: ""
      },
      chat: {} as any,
      isSending: false
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile",
      messages: "message/getMessages"
    })
  },
  mounted(): void {
    this.getChats();
  },
  methods: {
    playMessageSound() {
      //const audio = new Audio(require("@/assets/sounds/notification.wav"));
      //audio.play();
    },
    getChats() {
      this.loading = true;
      let chatData;
      if (this.type === "session") {
        chatData = this.$store.dispatch(
          "session/GET_CHAT",
          this.$route.params.id
        );
      } else if (this.type === "mentorcast") {
        chatData = this.$store.dispatch(
          "mentorcast/GET_CHAT",
          this.$route.params.id
        );
      } else {
        // Should add mentoringProgram/GET_CHAT here
        chatData = this.$store.dispatch(
          "program/GET_CHAT",
          this.$route.params.id
        );
      }
      chatData.then(data => {
        this.chat = data;
        this.loading = false;

        this.$store.commit("chat/SET_ACTIVE_CHAT_ID", data.id);

        this.getMessages();

        this.createRoom();
      });
    },
    createRoom() {
      Socket().emit("create-room", {
        room: this.chat.room_id,
        user_id: this.chat.user_id,
        chat_id: this.chat.id
      });
    },
    getMessages() {
      this.$store.dispatch("message/GET_MESSAGES", {
        chat: this.chat.id,
        page: 1
      });
    },
    onSendMessage() {
      if (this.isSending) {
        return;
      }

      if (this.data.message.trim().length > 0) {
        this.isSending = true;

        const URLMatcher = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\\/%=~_|$])/gim;
        const message = this.data.message.replace(
          URLMatcher,
          match => `<a target="_blank" href="${match}">${match}</a>`
        );

        const data = {
          id: null,
          chat_id: this.chat.id,
          user_id: this.profile.id,
          receiver_id: this.chat.user_id,
          message,
          delivered_at: null,
          seen_at: null,
          time: null,
          created_on: null,
          user: {
            id: this.profile.id,
            name: this.profile.name,
            profile_pic: this.profile.profile_pic,
            profile_link: this.profile.profile_link
          }
        };

        Socket().emit("message-send", {
          ...data,
          room_id: this.chat.room_id
        });

        this.$store.commit("message/APPEND_MESSAGE", data);

        this.data.message = "";
        (this as any).$refs.input.focus();
        this.isSending = false;
      }
    },
    insert(emoji: any) {
      this.data.message += emoji;
    }
  }
});
