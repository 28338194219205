
import Vue from "vue";
import { mapGetters } from "vuex";
import _ from "lodash";

export default Vue.extend({
  name: "ParticipantsModal",
  data() {
    return {
      // Should be removed
      filter: {
        searchVal: "",
        order_by: ""
      },
      orderOptions: [
        {
          id: "seat-color",
          name: (this as any).$t(
            "mentorcastHero.auditoriumParticipantModalFilterSeatColor"
          ),
          icon: "upgraded-seat"
        },
        {
          id: "seat-price",
          name: (this as any).$t(
            "mentorcastHero.auditoriumParticipantModalFilterSeatPrice"
          ),
          icon: "paid"
        },
        {
          id: "name",
          name: (this as any).$t(
            "mentorcastHero.auditoriumParticipantModalFilterName"
          ),
          icon: "user-profile"
        },
        {
          id: "joined",
          name: (this as any).$t(
            "mentorcastHero.auditoriumParticipantModalFilterJoined"
          ),
          icon: "profile-green"
        },
        {
          id: "not_joined",
          name: (this as any).$t(
            "mentorcastHero.auditoriumParticipantModalFilterNotJoined"
          ),
          icon: "profile-filled"
        }
      ],
      searching: false,
      // Select icons
      OpenIndicator: {
        render: (createElement: any) =>
          createElement("inline-svg", {
            attrs: {
              src: require(`@/assets/icons/arrow.svg`)
            }
          })
      }
    };
  },
  computed: {
    ...mapGetters({
      platformCurrency: "helper/getDefaultPlatformCurrency",
      participants: "mentorcast/getMentorcastParticipants"
    })
  },
  created: function(): void {
    this.$store.dispatch("helper/GET_DEFAULT_PLATFORM_CURRENCY");
  },
  methods: {
    search() {
      this.debounceSearch(this);
    },
    debounceSearch: _.debounce((vm: any) => {
      vm.getParticipants();
    }, 200),
    getParticipants() {
      this.searching = true;
      this.$store
        .dispatch("mentorcast/GET_MENTORCAST_PARTICIPANTS", {
          id: this.$route.params.id,
          data: this.filter
        })
        .then(() => {
          this.searching = false;
        })
        .catch(() => {
          this.searching = false;
        });
    },
    showMessages(user: any) {
      if (user.is_guest) {
        this.$emit("guestMessage", user);
        (this as any).$refs["participants-modal"].hide();
      } else {
        this.openNewTab({ path: `/conversations/${user.id}` });
      }
    },
    openNewTab(routeObject: any) {
      const { href } = this.$router.resolve(routeObject);
      window.open(href, "_blank");
    },
    broadcastMessage(): void {
      const recipients: string[] = [];
      this.participants.map((participant: any) => {
        if (participant?.user?.email) {
          recipients.push(participant.user.email);
        }
      });
      this.$emit("openBroadcastModal", recipients);
    },
    onDownload() {
      this.$store
        .dispatch(
          "mentorcast/GENERATE_MENTORCAST_PARTICIPANTS",
          this.$route.params.id
        )
        .then(data => {
          console.log(data);
          this.downloadURI(data, "participants.xlsx");
        });
    },
    downloadURI(data: any, name: any) {
      const blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = name;
      link.click();
    }
  }
});
