
import Vue from "vue";
import TermsAndConditionModal from "@/components/Modals/TermsAndConditionModal.vue";
import { mapGetters } from "vuex";
import { Mentorcast } from "@/types/interfaces";
import ClipperModal from "@/components/ClipperModal.vue";

export default Vue.extend({
  name: "ReserveSeatModal",
  components: {
    TermsAndConditionModal,
    ClipperModal
  },
  props: {
    mentorcast: {
      type: Object as () => Mentorcast
    }
  },
  data() {
    return {
      i_agree: false,
      loading: false,
      defaultSeatOption: "standard",
      isSeatSelected: false,
      availableSeatOptions: [],
      seatOptions: new Set(),
      data: {
        email: "",
        password: ""
      },
      imageUrl: "",
      guest: {
        url: require("@/assets/icons/profile-avatar.svg"),
        image: "",
        email: "",
        name: ""
      },
      errors: {} as any,
      passwordFieldType: "password",
      socialIcons: [
        { name: "google", link: "", color: "#1DA1F2", label: "Google" },
        { name: "facebook-new", link: "", color: "#1DA1F2", label: "Facebook" }
        // { name: "linkedin", link: "", color: "#1DA1F2", label: "Linkedin" }
      ],
      selectedProvider: ""
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile",
      selectedSeat: "booking/getSelectedSeat",
      termsAndConditionLink: "helper/getTermsAndConditionLink",
      seats: "mentorcast/getMentorcastSeats",
      platformCurrency: "helper/getDefaultPlatformCurrency"
    })
  },
  mounted(): void {
    this.getSeats();
  },
  methods: {
    selectedSeatOption(option: any) {
      if (option) {
        console.log(option, "selected option type");
        const filteredData = this.availableSeatOptions.filter(
          item => item["color"] === option.color
        );
        console.log(filteredData[0], "autp selected option");
        this.$emit("reserveSeat", filteredData[0]);
      } else {
        this.$emit("reserveSeat");
      }
    },
    selectSeat(seleted: any = true) {
      console.log("reserve seat modal");
      this.isSeatSelected = seleted;
    },
    getSeats() {
      this.loading = true;

      this.$store
        .dispatch("mentorcast/GET_MENTORCAST_SEATS", {
          id: this.$route.params.id,
          page: 1,
          all_seats: true
        })
        .then(data => {
          this.loading = false;
          const seatsData = data.data;
          this.availableSeatOptions = seatsData.filter(
            (seat: any) => seat.price !== null && seat.available === true
          );
          if (this.availableSeatOptions.length > 0) {
            /*console.log(this.availableSeatOptions, 'this.availableSeatOptions');*/

            this.seatOptions = seatsData.filter((item: any) => {
              if (
                !this.seatOptions.has(item.color) &&
                item.price !== null &&
                item.available == true
              ) {
                this.seatOptions.add(item.color);
                return true;
              }
              return false;
            });
            const seatOptionsArray = Array.from(this.seatOptions);

            seatOptionsArray.sort(
              (a: any, b: any) => parseFloat(a.price) - parseFloat(b.price)
            );

            this.seatOptions = new Set(seatOptionsArray);
            /*console.log(this.seatOptions, 'this.seatOptions');*/
          }
        });
    },
    switchPasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    onSubmit() {
      if (this.data.email === "" || this.data.password === "") return;
      this.loading = true;

      this.$store
        .dispatch("auth/LOGIN_USER", this.data)
        .then(() => {
          this.loading = false;
          // @ts-ignore
          this.$alertify.success("Login successful");

          if (this.profile.id != this.mentorcast?.user_id) {
            this.$emit("reserve");
          }
          this.$bvModal.hide("reserve-seat");
          this.isSeatSelected = false;
        })
        .catch(error => {
          this.loading = false;
          if (error.response.status === 422) {
            for (const e of error.response.data.errors) {
              // @ts-ignore
              this.$alertify.error(e);
            }
          }
        });
    },
    doSocialLogin() {
      const provider = this.selectedProvider;
      if (!provider) {
        return;
      }
      this.$bvModal.hide("terms-and-condition");

      (this as any).$auth
        .authenticate(provider)
        .then((response: unknown) => {
          this.$store
            .dispatch("auth/SOCIAL_LOGIN", { provider, response })
            .then(() => {
              (this as any).$alertify.success(
                `Login Successfully Using ${provider.toLocaleUpperCase()}`
              );
              console.log("hellllllo");
              this.$emit("update");
              this.$emit("reserve");
              this.$bvModal.hide("reserve-seat");
              this.isSeatSelected = false;
            })
            .catch((error: any) => {
              if (error.response.status === 422) {
                // @ts-ignore
                this.$alertify.error(error.response.data.errors[0]);
              }
            });
        })
        .catch((error: any) => {
          // @ts-ignore
          this.$alertify.error(error.response.data.message);
        });
    },
    onSocialLogin(provider: string) {
      this.selectedProvider = provider;
      this.$bvModal.show("terms-and-condition");
    },
    onSignup() {
      localStorage.setItem("intendedRoute", this.$route.path);
      this.$router.push("/register");
    },
    hasError(type: string) {
      if (type in this.errors && this.errors[type][0].length > 0) {
        return { error: this.errors[type][0] };
      }
      return null;
    },
    onGuestUserImage(data: any) {
      this.guest.image = data.image;
      this.guest.url = data.url;
      this.$bvModal.hide("profile-photo");
    },
    onGuestLogin() {
      if (this.guest.email === "") return;
      this.loading = true;

      this.$store
        .dispatch("auth/REGISTER_GUEST_USER", {
          ...this.guest,
          mentorcast_id: this.mentorcast.id
        })
        .then(() => {
          this.i_agree = false;

          this.guest.email = "";
          this.guest.image = "";
          this.guest.name = "";
          this.guest.url = require("@/assets/icons/profile-avatar.svg");

          this.loading = false;
          // @ts-ignore
          this.$emit("reserve");
          this.$bvModal.hide("reserve-seat");
          this.isSeatSelected = false;
        })
        .catch(error => {
          console.log(error.response);
          this.loading = false;
          if (error.response.status === 422) {
            for (const e of error.response.data.errors) {
              // @ts-ignore
              this.$alertify.error(e);
            }
          }
        });
    },
    onFileChange(event: any) {
      const file = event.target.files[0];
      this.imageUrl = URL.createObjectURL(file);
      this.$bvModal.show("clipper");
      const elImg = document.getElementById("image");
      if (elImg) {
        // @ts-ignore
        elImg.value = "";
      }
    },
    setTempFile(data: any) {
      this.guest.url = data.url;
      this.guest.image = data.temp;
    },
    showImageUpload() {
      // @ts-ignore
      document.getElementById("image").click();
    }
  }
});
