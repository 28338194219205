
import Vue from "vue";
import AddContentModal from "@/components/Contents/AddContentModal.vue";

export default Vue.extend({
  name: "ContentHeader",
  components: {
    AddContentModal
  },
  props: {
    isMentor: Boolean
  },
  methods: {
    refreshContent() {
      this.$emit("refreshContent");
    }
  }
});
