
import Vue from "vue";
import MentorcastHero from "@/components/Mentorcast/MentorcastHero.vue";
import { mapGetters } from "vuex";
import MentorcastHeroLoading from "@/components/Mentorcast/MentorcastHeroLoading.vue";
import MentorcastPasswordModal from "@/components/Modals/MentorcastPasswordModal.vue";
import Socket from "@/services/Socket";
import MentorcastHeroTabs from "@/components/Mentorcast/MentorcastHeroTabs.vue";

export default Vue.extend({
  name: "HeroPage",
  components: {
    MentorcastHero,
    MentorcastHeroLoading,
    MentorcastPasswordModal,
    MentorcastHeroTabs
  },
  data: function() {
    return {
      loading: true,
      loadMoreBtn: false,
      canAccess: true
    };
  },
  computed: {
    ...mapGetters({
      mentorcast: "mentorcast/getMentorcast",
      profile: "profile/getProfile"
    })
  },
  mounted: function(): void {
    this.getMentorcast(true);

    Socket().on(`mentorcast.${this.$route.params.id}.updated`, () => {
      this.getMentorcast(true);
    });
  },
  methods: {
    getMentorcast(isDefault = false) {
      if (isDefault) this.loading = true;

      this.$store
        .dispatch("mentorcast/GET_MENTORCAST", this.$route.params.id)
        .then(() => {
          if (isDefault) this.loading = false;
          if (this.mentorcast.has_invitational && isDefault) {
            this.canAccess = false;
            this.$bvModal.show("mentorcast-password");
          }
        })
        .catch(() => {
          // if (err.response.status == 403) {
          // 	this.$router.push('/')
          // }
        });
    },
    checkMentorcastAccess(isAccess: boolean) {
      if (!this.canAccess) {
        this.canAccess = isAccess;
      }
      if (!this.canAccess) {
        this.$router.push("/profile");
      }
      this.$bvModal.hide("mentorcast-password");
    }
  }
});
