
import Vue from "vue";
// import ShareDropdown from "@/components/ShareDropdown.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "UserDetailsModal",
  components: {
    // ShareDropdown
  },
  props: {
    profile: Object
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      loggedProfile: "profile/getProfile"
    })
  },
  methods: {
    profileLink(data: any) {
      if (data.type == "mentee") {
        return `/mentorees/${data.uuid}`;
      }
      if (data.type == "mentor") {
        return `/mentors/${data.uuid}`;
      }
    },
    getCategoryCount(type: any) {
      return this.profile?.category_types[type]?.length > 0;
    },
    checkIsCurrentUser() {
      return this.loggedProfile.id == this.profile.id;
    }
  }
});
