
import Vue from "vue";
import { InformationData, Mentorcast } from "@/types/interfaces";
import moment from "moment";
import { mapGetters } from "vuex";
import MentorcastImageModal from "@/components/Modals/MentorcastImageModal.vue";
import ShareDropdown from "@/components/ShareDropdown.vue";
import DiscountModal from "@/components/Modals/DiscountModal.vue";
import AuditoriumCategories from "@/components/Mentorcast/AuditoriumCategories.vue";

export default Vue.extend({
  name: "MentorcastHero",
  components: {
    MentorcastImageModal,
    ShareDropdown,
    DiscountModal,
    AuditoriumCategories
  },
  props: {
    mentorcast: {
      type: Object as () => Mentorcast
    }
  },
  data() {
    return {
      url: window.location.href,
      startIn: "",
      current_date: moment().format("YYYY-MM-DD"),
      currentTime: {},
      // Mentorcast information table data
      infoData: [
        {
          name: "start_date",
          label: (this as any).$t("mentorcastHero.labelDate"),
          icon: "calendar",
          value: null
        },
        {
          name: "start_time",
          label: (this as any).$t("mentorcastHero.labelTime"),
          icon: "time",
          value: null
        },
        {
          name: "seat_price",
          label: (this as any).$t("mentorcastHero.labelPricePerSeat"),
          icon: "price",
          value: null
        },
        {
          name: "audience",
          label: (this as any).$t("mentorcastHero.labelAudience"),
          icon: "3users",
          value: null
        },
        {
          name: "max_seats",
          label: (this as any).$t("mentorcastHero.labelNoOfSeat"),
          icon: "seat-block",
          value: null
        }
      ] as InformationData[],
      isAutoRefreshBeforeTenMin: false,
      isListenForAutoRefresh: false,
      autoRefreshTimerValue: 600000
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    }),
    isZeroPrice(): any {
      return this.mentorcast.seat_price <= 0;
    }
  },
  mounted(): void {
    // Find mentorcast information by name
    const start_in = `${this.mentorcast.start_date} ${this.mentorcast.start_time}`;
    setTimeout(() => this.countdown(start_in), 1);

    for (const key of Object.keys(this.mentorcast)) {
      const index = this.infoData.findIndex(i => i.name === key);
      if (index > -1) {
        if (key === "start_date") {
          this.infoData[index].value = moment(start_in)
            .format("Do MMMM, yyyy")
            .toString();
        } else if (key === "start_time") {
          this.infoData[index].value = moment(
            this.mentorcast[key],
            "hh:mm:ss"
          ).format("hh:mm A");
        } else if (key === "seat_price") {
          this.infoData[index].value = `$${
            this.mentorcast[key as keyof Mentorcast]
          }`;
        } else
          this.infoData[index].value = this.mentorcast[key as keyof Mentorcast];
      }
    }
  },
  methods: {
    // Calculate how much time to start session
    countdown(deadline: string) {
      const t = Date.parse(deadline) - Date.parse(new Date().toString());
      // const seconds = Math.floor((t / 1000) % 60);
      const minutes = Math.floor((t / 1000 / 60) % 60);
      const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
      const days = Math.floor(t / (1000 * 60 * 60 * 24));
      if (t > 0) {
        const currentTime = {
          total: t,
          days: days,
          hours: hours,
          minutes: minutes
        };
        /*seconds: seconds*/

        if (
          this.isListenForAutoRefresh &&
          t < this.autoRefreshTimerValue &&
          !this.isAutoRefreshBeforeTenMin
        ) {
          this.$emit("refresh");
          this.isAutoRefreshBeforeTenMin = true;
        }

        if (t > this.autoRefreshTimerValue) {
          this.isListenForAutoRefresh = true;
        }

        const dayStr = `${
          currentTime.days
            ? currentTime.days +
              " " +
              (this as any).$t("mentorcastHero.daysLabel")
            : ""
        }`;
        const hourStr = ` ${
          currentTime.hours
            ? currentTime.hours +
              " " +
              (this as any).$t("mentorcastHero.hoursLabel")
            : ""
        }`;
        const minStr = ` ${
          currentTime.minutes
            ? currentTime.minutes +
              " " +
              (this as any).$t("mentorcastHero.minutesLabel")
            : ""
        }`;

        const array = { dayStr, hourStr, minStr };

        const timeString = Object.values(array)
          .filter((e: any) => e.value != "")
          .join(" ")
          .trim();

        this.startIn = this.currentTime ? timeString : "";

        setTimeout(() => this.countdown(deadline), 1000);
      } else {
        this.currentTime = {};
      }
    }
  }
});
