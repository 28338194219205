
import Vue from "vue";
import { mapGetters } from "vuex";
import ChatSidebarLoading from "@/components/ChatSidebarLoading.vue";

export default Vue.extend({
  name: "ChatSidebar",
  components: {
    ChatSidebarLoading
  },
  props: {
    activeChatId: {},
    chats: Array,
    loading: Boolean,
    profile: Object
  },
  data() {
    return {
      search: ""
    };
  },
  computed: {
    ...mapGetters({
      users: "chat/getChatUsers"
    }),
    getChats(): any {
      return this.search
        ? this.searchInArray(this.search, this.chats, "name")
        : this.chats;
    }
  },
  methods: {
    handleSearch() {
      this.$store.dispatch("chat/GET_CHAT_USERS", {
        page: 1,
        q: this.search
      });
    },
    searchInArray(searchQuery: any, array: any, objectKey: string) {
      return array.filter((d: any) => {
        //@ts-ignore
        const data = d[objectKey]; //Incase If It's Array Of Objects.
        //@ts-ignore
        const dataWords =
          typeof data == "string" &&
          (data
            ?.split(" ")
            ?.map(b => b && b.toLowerCase().trim())
            .filter(b => b) as any);
        //@ts-ignore
        const searchWords =
          typeof searchQuery == "string" &&
          searchQuery
            ?.split(" ")
            .map(b => b && b.toLowerCase().trim())
            .filter(b => b);
        //@ts-ignore
        const matchingWords = searchWords.filter(
          (word: any) =>
            dataWords.findIndex((dataWord: any) => {
              return dataWord.includes(word);
            }) > -1
        );
        return matchingWords.length;
      });
    }
  }
});
