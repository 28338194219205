
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "LaunchModal",
  props: {
    title: {
      type: String
    },
    isMentor: {
      type: Boolean
    },
    isSession: {
      type: Boolean
    },
    booking: {
      type: Object
    }
  },
  data() {
    return {
      btnLoading: false,
      data: {
        mentoring_platform_id: parseInt(this.booking?.mentoring_platform_id),
        session_url: this.isSession
          ? this.booking.session_url
          : this.booking.host_url,
        password: this.booking?.password
      } as any,
      errors: {} as any,
      showPassword: true,
      OpenIndicator: {
        render: (createElement: any) =>
          createElement("inline-svg", {
            attrs: {
              src: require(`@/assets/icons/arrow.svg`)
            }
          })
      },
      emptyMessages: {
        session: {
          heading: "Your Mentor has not started the session yet.",
          paragraph: "Please contact them from the chat sector below"
        },
        mentorcast: {
          heading: "Your Mentor has not started the mentorcast yet.",
          paragraph: "Please wait.."
        }
      }
    };
  },
  computed: {
    getHeading(): any {
      const type = this.isSession ? "session" : "mentorcast";
      return this.emptyMessages[type]?.heading;
    },
    getParagraph(): any {
      const type = this.isSession ? "session" : "mentorcast";
      return this.emptyMessages[type]?.paragraph;
    },
    ...mapGetters({
      platforms: "helper/getMentoringPlatforms"
    }),
    getPlatforms(): any {
      if (this.isSession) {
        return this.platforms.filter(
          (e: any) => e.name !== "Hosted by Mentorice"
        );
      }
      return this.platforms;
    }
  },
  watch: {
    "booking.is_launched": function(val) {
      if (val) {
        this.mapKeys();
      }
    }
  },
  mounted() {
    this.$store.dispatch("helper/GET_MENTORING_PLATFORMS");
  },
  created() {
    this.mapKeys();
  },
  methods: {
    mapKeys() {
      // for (const item in this.data) {
      // if (!this.isSession && item === "session_url") {
      //   this.data[item] = this.booking?.host_url || this.booking.session_url;
      //   continue;
      // }
      /*if (this.booking && this.booking[item]) {
          console.log(item, this.booking[item])
          this.data[item] = this.booking[item];
        }*/
      // }
    },
    onBtn() {
      this.isMentor ? this.onLaunch() : this.onJoin();
    },
    onLaunch() {
      this.btnLoading = true;

      this.$store
        .dispatch("booking/LAUNCH", {
          id: this.booking.uuid,
          type: this.isSession ? "session" : "mentorcast",
          data: this.data
        })
        .then(() => {
          this.btnLoading = false;
          // const url = this.booking?.host_url || this.data.session_url;
          window.open(
            this.data.session_url || this.booking.session_url,
            "_blank"
          );
          this.$emit("refresh");
          this.$bvModal.hide("launch");
        })
        .catch(error => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    onJoin() {
      this.btnLoading = true;

      this.$store
        .dispatch("booking/JOIN", {
          id: this.booking.uuid,
          type: this.isSession ? "session" : "mentorcast"
        })
        .then(() => {
          this.btnLoading = false;
          window.open(this.booking.session_url, "_blank");
          this.$bvModal.hide("launch");
        })
        .catch(error => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    hasError(type: string) {
      if (type in this.errors && this.errors[type][0].length > 0) {
        return { error: this.errors[type][0] };
      }
      return null;
    },
    onLaterOrRefresh() {
      if (this.booking.is_launched) {
        (this as any).$refs["launch-modal"].hide();
      } else {
        this.$emit("refresh");
      }
    },
    copySessionUrl(event: any) {
      const clipboardData =
        event?.clipboardData ||
        //@ts-ignore
        window?.clipboardData ||
        event?.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(this.data.session_url);
    }
  }
});
