
import Vue from "vue";

export default Vue.extend({
  name: "AuditoriumCategories",
  props: {
    categories: Array
  },

  methods: {
    onClickCategory(query: any) {
      this.$router.push({
        name: "DiscoverMentorcastsPage",
        params: { q: query }
      });
      /*this.$router.push({
        name: "AvailableMentorcastsPage",
        params: { categoryId: id }
      });*/
    }
  }
});
