
import Vue from "vue";
import { mapGetters } from "vuex";
import BadgesInfoModal from "@/components/Modals/BadgesInfoModal.vue";

export default Vue.extend({
  name: "MentorcastHeroRating",
  components: {
    BadgesInfoModal
  },
  data() {
    return {
      maxRating: 10
    };
  },
  computed: {
    ...mapGetters({
      ratings: "mentorcast/getMentorcastRatings"
    })
  },
  mounted() {
    this.$store.dispatch("mentorcast/GET_RATINGS", this.$route.params.id);
  }
});
