<template>
  <b-modal
    id="chat-attachment-modal"
    ref="chat-attachment-modal"
    centered
    hide-footer
    hide-header
    size="md"
  >
    <h3 class="text-center font-weight-semi-bold text-color-1 mb-9">
      {{ $t("setting.chatAttachmentModalHeading") }}
    </h3>
    <input
      id="file"
      type="file"
      class="form-group w-100"
      @change="onFileChange"
    />

    <b-row align-h="center" class="mt-3" no-gutters>
      <b-col class="d-flex justify-content-center px-3 mt-8" cols="auto">
        <b-button
          :disabled="!src"
          class="mx-2 mx-sm-0 main-btn"
          variant="primary"
          @click="save"
        >
          <b-spinner v-if="isLoading" class="mr-2" small></b-spinner>
          {{ $t("setting.chatAttachmentModalAttachBtn") }}
        </b-button>
      </b-col>
      <b-col class="d-flex justify-content-center px-3 mt-8" cols="auto">
        <b-button
          class="text-color-2 mx-2 mx-sm-0 main-btn"
          variant="outline-dark"
          @click="$bvModal.hide('chat-attachment-modal')"
          >{{ $t("setting.chatAttachmentModalAttachLaterBtn") }}
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "ChatAttachmentModal",
  components: {},
  props: {
    image: String
  },
  data() {
    return {
      file: "",
      src: this.image,
      isLoading: false
    };
  },
  watch: {
    image(newVal) {
      this.src = newVal;
    }
  },
  methods: {
    onFileChange(event) {
      this.file = event.target.files[0];
      this.src = URL.createObjectURL(this.file);
    },
    save() {
      this.isLoading = true;
      this.$store
        .dispatch("helper/STORE_CHAT_ATTACHMENT", this.file, this.activeChatId)
        .then(data => {
          this.isLoading = false;
          this.$emit("attachmentURL", data);
          this.$bvModal.hide("chat-attachment-modal");
        })
        .catch(error => {
          this.isLoading = false;
          console.log(error.response.data.errors.file[0]);
          this.$alertify.error(error.response.data.errors.file[0]);
        });
    }
  }
};
</script>
<style lang="scss">
.circle-cropper {
  width: 100%;
  background: #222;

  &__preview {
    border: solid 1px rgba(white, 0.15);
  }
}
</style>
