<template>
  <div class="h-100 d-flex flex-column">
    <b-row class="bg-white p-5 p-xl-6 mb-4" no-gutters>
      <b-col class="d-flex align-items-center">
        <div class="position-relative">
          <PuSkeleton height="2.25rem" width="2.25rem" circle class="d-block" />
        </div>
        <span class="ml-5">
          <PuSkeleton height="20px" width="140px" class="d-block" />
        </span>
      </b-col>
      <b-col class="d-flex align-items-center" cols="auto">
        <PuSkeleton
          v-for="(_, i) in 2"
          :key="i"
          height="18px"
          width="18px"
          class="d-block mr-6"
        />
      </b-col>
    </b-row>
    <ChatMessagesLoading />
  </div>
</template>

<script>
import Vue from "vue";
import ChatMessagesLoading from "@/components/Session/ChatMessagesLoading";

export default Vue.extend({
  name: "ChatMessagesContainerLoading",
  components: { ChatMessagesLoading }
});
</script>
