
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "TermsAndConditionModal",
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      termsAndConditionLink: "helper/getTermsAndConditionLink",
      paymentTermsLink: "helper/getPaymentTermsLink"
    })
  },
  methods: {}
});
