
import Vue from "vue";

export default Vue.extend({
  name: "ParticipantDropdown",
  props: {
    user: {
      type: Object
    },
    is_following: {
      type: Boolean
    }
  },
  methods: {
    unfollow() {
      this.$store
        .dispatch("follow/UNFOLLOW", {
          follower_id: this.user?.user_id,
          type: "followings"
        })
        .then(() => {
          this.$emit("update", {
            ...this.user,
            user: {
              ...this.user.user,
              is_following: false
            }
          });
        });
    },
    follow() {
      this.$store
        .dispatch("follow/FOLLOW", {
          follower_id: this.user.user_id
        })
        .then(() => {
          this.$emit("update", {
            ...this.user,
            user: {
              ...this.user.user,
              is_following: true
            }
          });
        });
    },
    openChatModal() {
      this.$emit("startChat", this.user);
    },
    setMute() {
      this.$store.dispatch("mentorcast/MUTE_MENTORCAST_PARTICIPANT", {
        id: this.$route.params.id,
        seat_id: this.user.id,
        is_muted: !this.user.is_muted
      });
      this.$emit("update", {
        ...this.user,
        is_muted: !this.user.is_muted
      });
    }
  }
});
