
import Vue from "vue";
import { mapGetters } from "vuex";
import ParticipantView from "@/components/Session/ParticipantView.vue";

export default Vue.extend({
  name: "Participants",
  components: {
    ParticipantView
  },
  data() {
    return {
      loading: true,
      particapants: [] as any[]
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile",
      messages: "message/getMessages"
    })
  },
  mounted(): void {
    this.getParticipants();
  },
  methods: {
    update(participant: any) {
      this.particapants = this.particapants.map((item: any) => {
        if (item.user.id === participant.user.id) {
          return participant;
        }
        return item;
      });
    },
    getParticipants() {
      this.loading = true;
      const parts = this.$store.dispatch(
        "mentorcast/GET_MENTORCAST_PARTICIPANTS",
        {
          id: this.$route.params.id,
          data: {
            searchVal: "",
            order_by: ""
          }
        }
      );

      parts.then(data => {
        this.particapants = data;
        this.loading = false;
      });
      parts.catch(() => {
        this.loading = false;
      });
    }
  }
});
