
import Vue from "vue";
import ShareDropdown from "@/components/ShareDropdown.vue";

export default Vue.extend({
  name: "MentorcastImageModal",
  components: {
    ShareDropdown
  },
  props: {
    mentorcast: Object,
    infoData: Array
  },
  data() {
    return {
      url: window.location.href
    };
  },
  computed: {
    info() {
      return this.infoData.filter(
        (el: any) => el.name !== "start_time" && el.name !== "audience"
      );
    },
    getMentorcastLink(): any {
      //@ts-ignore
      return `${process.env.VUE_APP_HOST_URL}/mentorcasts/${this.mentorcast.uuid}`;
    }
  }
});
