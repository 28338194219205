
import Vue from "vue";
import { Mentorcast } from "@/types/interfaces";
import MentorImageModal from "@/components/Modals/MentorImageModal.vue";
import LaunchModal from "@/components/Modals/LaunchModal.vue";
import UserDetailsModal from "@/components/Modals/UserDetailsModal.vue";
import LoginModal from "@/components/Modals/LoginModal.vue";
import ReserveSeatModal from "@/components/Modals/ReserveSeatModal.vue";
import AuditoriumInfo from "@/components/Mentorcast/AuditoriumInfo.vue";
import Seats from "@/components/Mentorcast/Seats.vue";

export default Vue.extend({
  name: "MentorcastAuditorium",
  components: {
    MentorImageModal,
    LaunchModal,
    UserDetailsModal,
    LoginModal,
    AuditoriumInfo,
    Seats,
    ReserveSeatModal
  },
  props: {
    mentorcast: {
      type: Object as () => Mentorcast
    },
    profile: Object
  },
  data() {
    return {
      availableSeats: {},
      data: {
        row: 0,
        column: 0,
        page: 1
      },
      usedColors: []
    };
  },

  methods: {
    onGuestRegistered() {
      //@ts-ignore
      const { row, column } = this.$store.state.booking.seat;
      const { price } = (this as any).$refs["seats"].findSeat(row, column);
      const seatPrice = price ? price : this.mentorcast.seat_price;
      (this as any).$refs["seats"].getSeat(row, column, seatPrice);
    },
    onSeatReserve(data: any) {
      console.log(data);
      //@ts-ignore
      this.$refs["seats"].onSeatReserve(data);
    },
    seatSelected(data: any) {
      console.log("seatSelected", data);
      //@ts-ignore
      this.$refs["reserve-seat-modal"].selectSeat(data);
    }
  }
});
