
import Vue from "vue";

export default Vue.extend({
  name: "PaypalCheckout",
  props: {
    data: Object,
    seat: Object
  },
  methods: {
    mount() {
      const script = document.createElement("script");
      script.src = "https://www.paypalobjects.com/api/checkout.js";
      script.addEventListener("load", this.load);
      document.body.appendChild(script);
    },
    load() {
      const vm = this;
      //@ts-ignore
      window.paypal.Button.render(
        {
          env: process.env.VUE_APP_PAYPAL_MODE,
          client: {
            [process.env.VUE_APP_PAYPAL_MODE]: process.env.VUE_APP_PAYPAL_KEY
          },
          style: {
            size: "small",
            color: "silver"
          },
          payment: function() {
            return vm.createPayment();
          },
          onAuthorize: function(data: any) {
            vm.$emit("checkout", {
              payment_id: data.paymentID,
              payer_id: data.payerID
            });
          }
        },
        "#paypal-btn"
      );
    },
    createPayment() {
      return this.$store
        .dispatch("paypalPayment/CREATE_PAYMENT", {
          ...this.data,
          ...this.seat
        })
        .then(data => {
          return data.id;
        });
    }
  }
});
