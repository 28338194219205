
import Vue from "vue";
import { mapGetters } from "vuex";
import { Mentorcast, PaginationResult } from "@/types/interfaces";
import SeatTooltip from "@/components/Mentorcast/SeatTooltip.vue";
import Socket from "@/services/Socket";
import PaymentModal from "@/components/Modals/PaymentModal.vue";
import PaymentCompleteModal from "@/components/Modals/PaymentCompleteModal.vue";
import CustomizeSeatTooltip from "@/components/Mentorcast/CustomizeSeatTooltip.vue";
import SeatInfoTooltip from "@/components/Mentorcast/SeatInfoTooltip.vue";
import AllSeatsLoading from "@/components/Mentorcast/AllSeatsLoading.vue";
// import SeatsPagination from "@/components/Mentorcast/SeatsPagination.vue";
import ChatModal from "@/components/Modals/ChatModal.vue";

export default Vue.extend({
  name: "Seats",
  components: {
    ChatModal,
    SeatTooltip,
    CustomizeSeatTooltip,
    SeatInfoTooltip,
    PaymentModal,
    PaymentCompleteModal,
    AllSeatsLoading
    // SeatsPagination
  },

  props: {
    mentorcast: {
      type: Object as () => Mentorcast
    },
    profile: Object,
    seatData: Object
  },

  data() {
    return {
      data: {
        row: 0,
        column: 0,
        page: 1
      },
      chatUser: {} as any,
      seatColors: [
        {
          label: (this as any).$t("mentorcastHero.mentorcastSeatsColorRed"),
          value: "red"
        },
        {
          label: (this as any).$t("mentorcastHero.mentorcastSeatsColorGreen"),
          value: "green"
        },
        {
          label: (this as any).$t("mentorcastHero.mentorcastSeatsColorPurple"),
          value: "purple"
        },
        {
          label: (this as any).$t("mentorcastHero.mentorcastSeatsColorVIP"),
          value: "vip"
        }
      ],
      seatRows: [] as any,
      seatRowsMin: 0,
      seatColumns: 0,
      seatsData: {} as any,
      usedColors: {} as any,
      paymentSeatData: {},
      load_more: false,
      loading: true,
      isOpenedInPopup: true,
      currentPage: 1,
      totalPages: 1
    };
  },

  computed: {
    ...mapGetters({
      platformCurrency: "helper/getDefaultPlatformCurrency",
      seats: "mentorcast/getMentorcastSeats"
    }),
    isZeroPrice(): any {
      return this.mentorcast.seat_price <= 0;
    },
    activePageData(): [] {
      return this.seatRows[this.currentPage - 1];
    }
  },
  created: function(): void {
    this.$store.dispatch("helper/GET_DEFAULT_PLATFORM_CURRENCY");
  },

  mounted(): void {
    this.seatsData = {};

    Socket().on(`mentorcast.${this.mentorcast.id}.seats`, data => {
      if (data.currentUserId == this.profile.id) {
        return;
      }
      this.$emit("refresh");
      this.seatsData = {};
      this.data.page = 1;
      this.getSeats();
    });

    this.getSeats();
  },

  beforeDestroy() {
    Socket().off(`mentorcast.${this.mentorcast.id}.seats`);
  },

  methods: {
    reserveSeat(data: any) {
      console.log("seat", data);
      if (data) {
        this.getSeat(data.number.r, data.number.g, data.price);
      }

      /*this.$emit("reserveSeat", data);*/
    },
    startChat(data: any) {
      this.chatUser = data;
      this.isOpenedInPopup = true;
      this.$bvModal.show("chat-modal");
      console.log("start chat -> ", this.chatUser);
    },
    async paymentComplete() {
      if (localStorage.getItem("isGuest")) {
        await this.$store.dispatch("auth/LOGOUT_USER");
      }
      this.$store
        .dispatch("mentorcast/GET_MENTORCAST", this.$route.params.id)
        .then(() => {
          this.resetSeat();
        })
        .catch(err => {
          console.error(err);
        });
    },
    updateCurrentPage(newPage: number) {
      this.currentPage = newPage;
      // Fetch data for the new page here
    },
    resetSeat() {
      this.seatsData = {};
      this.data.page = 1;
      this.seatColumns = 0;
      this.seatRowsMin = 0;
      this.seatRows = [];
      this.getSeats();
    },
    onTooltip(row: number, column: number) {
      if (this.profile?.id && this.findSeat(row, column)) {
        const user = this.findSeat(row, column).user;
        if (user.is_guest) {
          return;
        }
        user.type == "mentor"
          ? this.$router.push(`/mentors/${user.uuid}`)
          : this.$router.push(`/mentorees/${user.uuid}`);
      }
    },
    getSeats() {
      this.loading = true;

      this.$store
        .dispatch("mentorcast/GET_MENTORCAST_SEATS", {
          id: this.$route.params.id,
          page: this.data.page,
          all_seats: true
        })
        .then(data => {
          this.data.page = data.current_page;
          this.load_more = this.data.page < data.last_page;
          // eslint-disable-next-line no-prototype-builtins
          if (!this.seatsData.hasOwnProperty("data")) {
            this.seatsData = data;
          } else {
            this.seatsData.current_page = data.current_page;
            this.seatsData.last_page = data.last_page;
            this.seatsData.data.push(...data.data);
          }
          this.usedColors = this.seatsData.data
            .map((item: any) => item.color)
            .filter((color: any) => color !== null && color !== "default");
          this.seatColors = this.seatColors.filter(
            item => !this.usedColors.includes(item.value)
          );
          console.log(this.seatColors);

          this.setSeatsRows();
        });
    },
    onLoadMore() {
      this.data.page++;
      this.getSeats();
    },
    setSeatsRows() {
      if (this.seatsData && this.seatsData.data) {
        const rows = Math.max(
          ...this.seatsData.data.map((el: any) => el.number.r)
        );
        let RowsMin = Math.min(
          ...this.seatsData.data.map((el: any) => el.number.r)
        );
        this.seatRows = [];
        for (RowsMin; RowsMin <= rows; RowsMin++) {
          //@ts-ignore
          this.seatRows.push(RowsMin);
        }
        this.seatColumns = Math.max(
          ...this.seatsData.data.map((el: any) => el.number.g)
        );

        // const { paginatedArray, totalPages } : PaginationResult = this.paginateSeatRow(this.seatRows, 4);
        // this.seatRows = paginatedArray;
        // this.totalPages = totalPages

        this.loading = false;
      }
    },
    findSeat(row: number, column: number) {
      return this.seatsData?.data?.find(
        ({ number }: any) => number.r === row && number.g === column
      );
    },
    async getSeat(row: number, column: number, price = null) {
      this.data.row = row;
      this.data.column = column;

      this.paymentSeatData = this.findSeat(row, column);

      await (this as any).$refs["paymentModal"]?.selectSeat();
      this.$emit("seatSelected", true);
      await (this as any).$refs["paymentModal"]?.setPrice(
        price + " " + this.platformCurrency
      );
      (this as any).$refs["paymentCompleteModal"]?.setSeat();

      this.onParticipate(price);
    },
    onParticipate(price: any) {
      if (this.profile.id) {
        //Check the price if price is zero than skip payment modal.
        if (price > 0) {
          this.$bvModal.show("payment");
        } else {
          const reqData = {
            id: this.mentorcast && this.mentorcast.id,
            type: this.mentorcast ? "mentorcast" : "session"
          };
          this.$store
            .dispatch("booking/CREATE_BOOKING", {
              ...reqData,
              ...this.data
            })
            .then(() => {
              /*this.paymentComplete(); */
              this.$bvModal.show("payment-complete");
            })
            .catch(error => {
              if (error.response.status === 422) {
                for (const e of error.response.data.errors) {
                  // @ts-ignore
                  this.$alertify.error(e);
                }
              }
            });
        }

        /* this.$bvModal.show("payment");*/
      } else {
        this.$store.commit("booking/SET_SEAT", this.data);
        this.$bvModal.show("reserve-seat");
      }
    },
    onSeatReserve(seat: any) {
      this.selectRandomSeat(seat);
    },
    async selectRandomSeat(seat: any) {
      if (seat) {
        this.data.row = seat.number.r;
        this.data.column = seat.number.g;
        this.data.page = this.seats.current_page;
        this.getSeat(seat.number.r, seat.number.g, seat.number.price);
        this.$store.commit("booking/SET_SEAT", this.data);

        if (this.profile.id) {
          await (this as any).$refs["paymentModal"]?.selectSeat();
          this.$bvModal.show("payment");
        } else {
          this.$emit("seatSelected", true);
          this.$bvModal.show("reserve-seat");
        }
      } else {
        let seatData = {} as any;

        const chunks = this.onCHunks(this.seats.data)
          .map((e: any) => e.filter((f: any) => f.available && !f.isUpgraded))
          .filter((f: any) => f.length > 0);

        if (chunks[0] == null || chunks[0]?.length == 0) {
          (this as any).$alertify.error("All seats has been booked");
          return;
        }

        const random = Math.floor(Math.random() * chunks[0].length - 1) + 1;

        if (chunks[0][random]) {
          seatData = chunks[0][random].number;
          console.log("else reserve seat", random, seatData);
          this.data.row = seatData.r;
          this.data.column = seatData.g;
          this.data.page = this.seats.current_page;
          this.$store.commit("booking/SET_SEAT", this.data);

          if (this.profile.id) {
            await (this as any).$refs["paymentModal"]?.selectSeat(false);
            this.$bvModal.show("payment");
          } else {
            this.$emit("seatSelected", false);
            this.$bvModal.show("reserve-seat");
          }
        }
      }
    },
    onCHunks(array: any) {
      const perChunk = 12;

      return array.reduce((resultArray: any, item: any, index: any) => {
        const chunkIndex = Math.floor(index / perChunk);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [];
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []);
    },
    isUpgradedSeat(i: any, j: any) {
      const seat = this.findSeat(i, j);

      return seat.color && seat.color !== "default";
    },
    async onHidePaymentModal() {
      await (this as any).$refs["paymentModal"]?.setPrice(
        this.mentorcast?.seat_price + " " + this.platformCurrency
      );
      this.paymentSeatData = {};
    },
    onAddCustomSeat(data: any) {
      if (data.is_upgrade_full_row) {
        this.resetSeat();
        return;
      }
      const seatIndex = this.seatsData?.data?.findIndex(
        ({ number }: any) => number.r === data.row && number.g === data.column
      );
      if (seatIndex > -1) {
        this.seatsData.data[seatIndex].loading = true;

        setTimeout(() => {
          this.seatsData.data[seatIndex].title = data.title;
          this.seatsData.data[seatIndex].color = data.color;
          this.seatsData.data[seatIndex].price = parseFloat(data.price).toFixed(
            2
          );
          this.seatsData.data[seatIndex].loading = false;
        }, 200);
      }
    },
    hideCustomizeTooltips() {
      this.$root.$emit("bv::hide::tooltip", "customize-seat-tooltip");
    },
    paginateSeatRow(
      seatRows: number[],
      maxPaginationItems: number
    ): PaginationResult {
      const paginatedArray = seatRows.reduce(
        (result: number[][], item: number, index: number) => {
          const pageIndex = Math.floor(index / maxPaginationItems);

          if (!result[pageIndex]) {
            result[pageIndex] = [];
          }

          result[pageIndex].push(item);
          return result;
        },
        []
      );

      const totalPages: number = paginatedArray.length;

      return { paginatedArray, totalPages };
    }
  }
});
