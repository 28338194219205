
import Vue from "vue";
import ChatSidebar from "@/components/ChatSidebar.vue";
import ChatMessage from "@/components/ChatMessage.vue";
import { mapGetters } from "vuex";
import Socket from "@/services/Socket";

export default Vue.extend({
  name: "ChatContainer",
  components: { ChatMessage, ChatSidebar },
  props: {
    isOpenedInPopup: Boolean,
    chatUser: Object
  },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile",
      chats: "chat/getChats",
      activeChatId: "chat/getActiveChatId"
    })
  },
  mounted(): void {
    this.getChats();

    // Socket().on('chat-message', data => {
    // 	this.playMessageSound();
    //
    // 	if (data.user_id !== this.profile.id) {
    // 		if (this.activeChatId != data.chat_id) {
    // 			Socket().emit('message-delivered', {
    // 				id: data.id,
    // 				chat_id: data.chat_id,
    // 				room_id: data.room_id
    // 			})
    // 		}
    // 		this.$store.commit('chat/SET_MESSAGE_TO_CHAT', data)
    //
    // 		if (this.activeChatId == data.chat_id) {
    // 			Socket().emit('message-seen-delivered', {
    // 				id: data.id,
    // 				chat_id: data.chat_id,
    // 				room_id: data.room_id
    // 			})
    // 			this.$store.commit('message/APPEND_MESSAGE', data)
    // 		}
    // 	}
    // })

    Socket().on("disconnect", function() {
      console.log("Disconnected");
    });

    Socket().on("connect", () => {
      // thats the key line, now register to the room you want.
      // info about the required rooms (if its not as simple as my
      // example) could easily be reached via a DB connection. It worth it.
      console.log("socket connected");
      if (!this.activeChatId) {
        return;
      }
      this.openChat(this.activeChatId);
    });
  },
  beforeDestroy() {
    this.$store.commit("chat/SET_ACTIVE_CHAT_ID", null);
  },
  methods: {
    playMessageSound() {
      //const audio = new Audio(require("@/assets/sounds/notification.wav"));
      //audio.play();
    },
    getChats() {
      this.loading = true;
      this.$store
        .dispatch("chat/GET_CHATS", {
          page: 1
        })
        .then(chats => {
          if (this.$route.params.id) {
            this.checkAndActivateChat();
          } else {
            if (chats.length > 0) {
              this.openChat(chats[0].id);
            }
          }
          this.loading = false;
        });
    },
    openChat(id: number) {
      //@ts-ignore
      const index = this.chats.findIndex(i => i.id == id);
      if (index > -1) {
        const chat = this.chats[index];

        Socket().emit("create-room", {
          user_id: chat.user_id,
          room: chat.room_id,
          chat_id: chat.id
        });
        console.log("test", id);
        this.$store.commit("chat/SET_ACTIVE_CHAT_ID", id);
        // this.activeChatId = id;
        this.$store.commit("profile/DECREASE_MESSAGE_COUNT", id);
      }
    },
    checkAndActivateChat(): any {
      console.log("checkAndActivateChat", this.chatUser?.id);
      let userId = this.$route.params.id ?? this.chatUser?.id ?? null;
      if (this.chatUser) {
        userId = this.chatUser.id;
      }
      const index = this.chats.findIndex((i: any) => i.user_id == userId);
      if (index > -1) {
        this.openChat(this.chats[index].id);
        return;
      }
      this.createChat(userId);
    },
    createChat(id: any) {
      this.$store
        .dispatch("chat/CREATE_CHAT", {
          id
        })
        .then(data => {
          this.openChat(data.id);
        });
    }
  }
});
