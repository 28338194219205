
import Vue from "vue";
import { loadStripe } from "@stripe/stripe-js";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "StripeModal",
  data() {
    return {
      stripe: {},
      cardElement: {},
      btnLoading: false
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    })
  },
  methods: {
    async loadStripe() {
      const stripeStyles = {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      };
      //@ts-ignore
      this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY);
      //@ts-ignore
      const elements = this.stripe.elements();
      this.cardElement = elements.create("card", {
        style: stripeStyles
      });
      // @ts-ignore
      this.cardElement.mount("#card-element");
      // @ts-ignore
      this.cardElement.on("change", function(event: any) {
        const displayError = document.getElementById("card-errors");
        if (displayError) {
          if (event.error) {
            displayError.textContent = event.error.message;
          } else {
            displayError.textContent = "";
          }
        }
      });
    },
    async processPayment() {
      this.btnLoading = true;
      //@ts-ignore
      const { paymentMethod, error } = await this.stripe.createPaymentMethod(
        "card",
        this.cardElement,
        {
          billing_details: {
            name: this.profile.name
          }
        }
      );
      if (error) {
        this.btnLoading = false;
        if (error?.message) {
          //@ts-ignore
          this.$alertify.error(error.message);
        }
      } else {
        this.$store
          .dispatch("stripePayment/ADD_PAYMENT_METHOD", {
            payment_method_id: paymentMethod.id
          })
          .then(() => {
            this.btnLoading = false;
            this.$bvModal.hide("stripe");
          })
          .catch(() => {
            this.btnLoading = false;
          });
      }
    }
  }
});
