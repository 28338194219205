
import Vue from "vue";
import MentorcastCertificate from "@/components/Mentorcast/MentorcastCertificate.vue";
import AddCertificateModal from "@/components/Modals/AddCertificateModal.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "MentorcastCertificates",
  components: {
    AddCertificateModal,
    MentorcastCertificate
  },
  props: {
    type: {
      type: String,
      required: true
    },
    mentoringPlan: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: [] as any,
      page: 1,
      load_more: false
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    })
  },
  mounted() {
    this.getCertificates();
  },
  methods: {
    onLoadMore() {
      this.page++;
      this.getCertificates(this.page);
    },
    getCertificates(page = 1) {
      let certificates;

      if (this.type === "mentorcast") {
        certificates = this.$store.dispatch("mentorcast/GET_CERTIFICATES", {
          id: this.$route.params.id,
          page
        });
      } else {
        // Should add mentoringProgram/GET_CERTIFICATES here
        certificates = this.$store.dispatch("program/GET_CERTIFICATES", {
          id: this.$route.params.id,
          page
        });
      }

      certificates.then(data => {
        this.page = page;
        this.load_more = page < data.last_page;
        this.data.push(...data.data);
      });
    }
  }
});
