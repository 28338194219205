
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "SeatInfoTooltip",
  props: {
    target: String,
    seatNumber: String,
    seatData: Object,
    defaultSeatPrice: String,
    isBooked: Boolean
  },
  computed: {
    ...mapGetters({
      platformCurrency: "helper/getDefaultPlatformCurrency"
    })
  },
  created: function(): void {
    this.$store.dispatch("helper/GET_DEFAULT_PLATFORM_CURRENCY");
  }
});
