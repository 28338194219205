
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "SeatTooltip",
  components: {},
  props: {
    target: String,
    seatNumber: String,
    seatData: Object
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    })
  },
  methods: {
    openChatModal() {
      this.$emit("startChat", this.seatData.user);
      /*this.openNewTab({ path: `/conversations/${this.seatData.user.id}` });*/
    },
    showStatistics() {
      this.openNewTab({
        path: `/mentorees/${this.seatData.user.uuid}/statistics`
      });
    },
    openNewTab(routeObject: any) {
      const { href } = this.$router.resolve(routeObject);
      window.open(href, "_blank");
    },
    unfollow() {
      this.$store
        .dispatch("follow/UNFOLLOW", {
          follower_id: this.seatData.user.id,
          type: "followings"
        })
        .then(() => {
          this.$emit("update");
        });
    },
    follow() {
      this.$store
        .dispatch("follow/FOLLOW", {
          follower_id: this.seatData.user.id
        })
        .then(() => {
          this.$emit("update");
        });
    }
  }
});
