
import Vue from "vue";
import MentoringSessionChat from "@/components/Session/MentoringSessionChat.vue";
import Participants from "@/components/Session/Participants.vue";

export default Vue.extend({
  name: "ConversationTab",
  components: {
    MentoringSessionChat,
    Participants
  }
});
