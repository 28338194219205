
import Vue from "vue";
import LaunchModal from "@/components/Modals/LaunchModal.vue";
import { InformationData } from "@/types/interfaces";

export default Vue.extend({
  name: "PaymentCompleteModal",
  components: {
    LaunchModal
  },
  props: {
    booking: {
      type: Object
    },
    seat: {
      type: Object
    },
    isMentorcast: {
      type: Boolean
    }
  },
  data() {
    return {
      infoData: [
        {
          name: "date",
          label: (this as any).$t("mentorcastHero.PaymentModalDateLabel"),
          value: `${this.booking && this.booking.start_date} ${this.booking &&
            this.booking.start_time}`
        }
      ] as InformationData[],
      cardTypeOptions: [
        { label: "Debit Card", value: "visa", icon: "visa" },
        { label: "Debit Card", value: "paypal", icon: "paypal" },
        { label: "Debit Card", value: "mastercard", icon: "mastercard" }
      ]
    };
  },
  watch: {
    "seat.row": function() {
      this.setSeat();
    }
  },
  mounted(): void {
    if (this.isMentorcast) {
      this.infoData.unshift({
        name: "mentorcast",
        label: (this as any).$t("mentorcastHero.PaymentModalMentorcastLabel"),
        value: this.booking.title
      });
      this.setSeat();
    } else {
      this.infoData.unshift({
        name: "session",
        label: (this as any).$t(
          "mentorcastHero.PaymentModalCompleteSessionWithLabel"
        ),
        value: this.booking && this.booking.user && this.booking.user.name
      });
    }
  },
  methods: {
    setSeat() {
      if (!this.isMentorcast) {
        return;
      }
      const value = `R${this.seat.row} | C${this.seat.column}`;
      // const value = `P${this.seat.page} | R${this.seat.row} | C${this.seat.column}`
      const index = this.infoData.findIndex(i => i.name === "seat_number");
      if (index > -1) {
        this.infoData[index].value = value;
        return;
      }
      this.infoData.push({
        name: "seat_number",
        label: (this as any).$t("mentorcastHero.PaymentModalCompleteSeatLabel"),
        value
      });
    },
    setInfoData(booking: any) {
      this.infoData = [
        {
          name: "date",
          label: (this as any).$t("mentorcastHero.PaymentModalDateLabel"),
          value: `${booking.start_date} ${booking.start_time}`
        }
      ];

      this.infoData.unshift({
        name: "session",
        label: (this as any).$t(
          "mentorcastHero.PaymentModalCompleteSessionWithLabel"
        ),
        value: booking.user.name
      });
    },
    close() {
      this.$emit("close", true);
      (this as any).$refs["payment-complete-modal"].hide();
    },
    generateIcs() {
      const dispatcher = this.isMentorcast
        ? "mentorcast/GENERATE_ICS"
        : "session/GENERATE_ICS";
      const name = this.isMentorcast ? "Mentorcast" : "Mentoring Session";

      this.$store.dispatch(dispatcher, this.booking.uuid).then(data => {
        this.downloadURI(data.ics, name);
      });
    },
    downloadURI(uri: any, name: any) {
      const link = document.createElement("a");
      link.setAttribute("download", name);
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }
});
