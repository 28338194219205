
import Vue from "vue";
import { mapGetters } from "vuex";
import Socket from "@/services/Socket";
import MessageDeliveryIcon from "@/components/MessageDeliveryIcon.vue";
import ChatMessagesContainerLoading from "@/components/ChatMessagesContainerLoading.vue";
import ChatAttachmentModal from "@/components/ChatAttachmentModal.vue";

export default Vue.extend({
  name: "ChatMessage",
  components: {
    MessageDeliveryIcon,
    ChatMessagesContainerLoading,
    ChatAttachmentModal
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      }
    }
  },
  props: {
    activeChatId: Number,
    chats: Array,
    loadingChats: Boolean,
    isOpenedInPopup: Boolean
  },
  data() {
    return {
      chat: {
        room_id: ""
      } as any,
      message: "",
      attachment_details: {
        name: "",
        url: "",
        type: "",
        extension: ""
      } as any,
      buttons: {
        mentor: ["agenda", "mentorice-scene"],
        mentee: ["profile", "rating"]
      },
      isSending: false,
      loadingMessages: false
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile",
      messages: "message/getMessages"
    })
  },
  watch: {
    activeChatId(val: string) {
      if (val) {
        this.getChat();
        this.getMessages();
      }
    }
  },
  mounted() {
    this.getChat();
    this.getMessages();
  },
  methods: {
    getChat() {
      //@ts-ignore
      const index = this.chats.findIndex(i => i.id == this.activeChatId);
      if (index > -1) {
        //@ts-ignore
        this.chat = this.chats[index];
      }
    },
    getMessages() {
      if (!this.activeChatId) {
        return;
      }
      this.loadingMessages = true;
      this.$store
        .dispatch("message/GET_MESSAGES", {
          chat: this.activeChatId,
          page: 1
        })
        .then(() => (this.loadingMessages = false));
    },
    setMessage(data: any) {
      console.log("attachment url", data.url);
      this.attachment_details.name = data.name;
      this.attachment_details.url = data.url;
      this.attachment_details.extension = data.extension;
      this.attachment_details.type = data.type;
      this.message = data.url;
    },
    onSendMessage() {
      if (this.isSending) {
        return;
      }

      if (this.message.trim().length > 0) {
        this.isSending = true;

        const URLMatcher = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\\/%=~_|$])/gim;
        let message = this.message.replace(
          URLMatcher,
          match => `<a target="_blank" href="${match}">${match}</a>`
        );

        if (this.attachment_details.type == "image") {
          message = `<div class="media-message position-relative">
                        <a target="_blank" class="" href="${this.attachment_details.url}" download>
                          <img   class="img-fluid" style="height: 8rem; width: 8rem; object-fit: cover" src="${this.attachment_details.url}"  >
                        </a>
                        <p  class="my-1">${this.attachment_details.name}</p>
                    </div>`;
        } else if (this.attachment_details.type == "video") {
          message = `<div class="media-message position-relative">
                      <video controls  style="height: 8rem; width: 8rem;">
                        <source :src="${this.attachment_details.url}" >
                      </video>
                       <p  class="my-1">${this.attachment_details.name}</p>
                    </div>`;
        } else if (this.attachment_details.type == "file") {
          message = `<div class="media-message position-relative">
                        <p  class="align-i d-table-row justify-content-between my-1 row">
                            <span>${this.attachment_details.name} </span>
                            <a target="_blank" class="ml-1" href="${
                              this.attachment_details.url
                            }" download>
                              <img  class="img-fluid" style="height: 1rem; width:1rem; border-radius: 5px" src="${require("@/assets/icons/download-attachment.svg")}"  >
                            </a>
                        </p>
                    </div>`;
        }

        const data = {
          id: null,
          chat_id: this.activeChatId,
          user_id: this.profile.id,
          receiver_id: this.chat.user_id,
          type: this.attachment_details.type,
          extension: this.attachment_details.extension,
          attachment_name: this.attachment_details.name,
          message,
          delivered_at: null,
          seen_at: null,
          time: null,
          created_on: null,
          user: {
            id: this.profile.id,
            name: this.profile.name,
            profile_pic: this.profile.profile_pic,
            profile_link: this.profile.profile_link
          }
        };

        Socket().emit("message-send", {
          ...data,
          room_id: this.chat.room_id
        });

        this.$store.commit("message/APPEND_MESSAGE", data);
        this.$store.commit("chat/SET_MESSAGE_TO_CHAT", data);

        // this.$store.dispatch('message/CREATE_MESSAGE', {
        // 		message: this.message,
        // 		chat_id: this.activeChatId,
        // 		room_id: this.chat.room_id
        // 	})
        // 	.then(() => {
        this.message = "";
        this.attachment_details.name = "";
        this.attachment_details.url = "";
        this.attachment_details.type = "";
        this.attachment_details.extension = "";
        (this as any).$refs.input.focus();
        this.isSending = false;
        // })
        // .catch(() => {
        // 	this.isSending = false;
        // })
      }
    },
    getRoute(btn: any): string {
      const userId = this.chat.user_uuid;
      const routes = {
        mentor: {
          agenda: `/mentors/${userId}/agenda`,
          "mentorice-scene": `/mentors/${userId}/mentorcasts`
        },
        mentee: {
          profile: `/mentorees/${userId}`,
          rating: `/mentorees/${userId}/statistics`
        }
      } as any;
      return routes[this.chat.user_type][btn];
    },
    insert(emoji: any) {
      this.message += emoji;
    },
    openAttachmentModal() {
      console.log("open attachment modal");
      this.$bvModal.show("chat-attachment-modal");
    },
    downloadFile(message: any) {
      const link = document.createElement("a");
      link.setAttribute("download", message.attachment_name);
      /*link.setAttribute("target", '_blank');*/
      link.href = message.message;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }
});
