
import Vue from "vue";
import { mapGetters } from "vuex";
import ContentHeader from "@/components/Contents/ContentHeader.vue";
import ContentItem from "@/components/Contents/ContentItem.vue";
import MentorcastContentsSkeleton from "@/components/Contents/MentorcastContentsSkeleton.vue";
import EditContentModal from "@/components/Contents/EditContentModal.vue";
import DeleteContentModal from "@/components/Contents/DeleteContentModal.vue";
import ViewContentModal from "@/components/Contents/ViewContentModal.vue";

export default Vue.extend({
  name: "Content",
  components: {
    ViewContentModal,
    DeleteContentModal,
    EditContentModal,
    MentorcastContentsSkeleton,
    ContentItem,
    ContentHeader
  },
  props: {
    mentorcast: Object
  },
  data() {
    return {
      selectedContent: null as null | any,
      isEditModalOpen: false as boolean,
      isViewModalOpen: false as boolean,
      loading: false,
      data: [] as any,
      page: 1,
      load_more: false,
      last_page: 0
    };
  },
  computed: {
    ...mapGetters({
      /*mentorcastContents: "mentorcast_content/getMentorcastContent",*/
      profile: "profile/getProfile"
    }),
    isMentor(): boolean {
      return this.profile.type === "mentor";
    },
    previewImage(): string | null {
      if (this.selectedContent && "cover_image" in this.selectedContent) {
        return (this.selectedContent as { cover_image: string }).cover_image;
      }
      return null;
    }
  },
  mounted() {
    console.log(this.profile);
    /*this.getMentorCastContent(this.page);*/
  },
  methods: {
    getMentorCastContent() {
      this.loading = true;
      this.$store
        .dispatch("mentorcast_content/GET_ALL_CONTENT", {
          id: this.$route.params.id,
          data: {
            page: this.page
          }
        })
        .then(res => {
          this.loading = false;
          const data = res.data;
          console.log(data, " <- res");
          console.log(this.data, " <- this.data");
          this.page = data.current_page;
          this.load_more = this.page < data.last_page;
          this.data.push(...data.data);
        });
    },
    refreshContent() {
      this.data = [];
      this.page = 1;
      this.getMentorCastContent();
    },
    openEditModal({ content, name }: { content: any; name: string }) {
      this.isEditModalOpen = true;
      const modifiedContent = { ...this.modifyDataFor(content) };
      this.openModal(modifiedContent, name);
    },
    openDeleteModal({ content, name }: { content: any; name: string }) {
      console.log("oepn delete");
      this.isEditModalOpen = true;
      this.openModal(content, name);
    },
    openViewModal({
      content,
      name
    }: {
      content: any;
      name: string;
      purchase: boolean;
    }) {
      this.isViewModalOpen = true;
      this.openModal(content, name);
    },
    closeViewContentModal() {
      this.isViewModalOpen = false;
    },
    openModal(content: any, name: string) {
      this.selectedContent = content;
      this.$nextTick(() => this.$bvModal.show(name));
    },
    modifyDataFor(content: any) {
      content.content_src = content.content_source;
      if (content.content_src === "file") content.file_path = content.source;
      else content.file_url = content.source;
      return content;
    },
    onLoadMore() {
      this.page++;
      this.getMentorCastContent();
    }
  }
});
