import { render, staticRenderFns } from "./MentoringSessionChat.vue?vue&type=template&id=78319399&scoped=true"
import script from "./MentoringSessionChat.vue?vue&type=script&lang=ts"
export * from "./MentoringSessionChat.vue?vue&type=script&lang=ts"
import style0 from "./MentoringSessionChat.vue?vue&type=style&index=0&id=78319399&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78319399",
  null
  
)

export default component.exports