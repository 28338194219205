
import Vue from "vue";
// import ConversationsPage from "@/views/ConversationsPage.vue";
import Ratings from "@/components/Ratings/Ratings.vue";
import SeatsLoading from "@/components/Mentorcast/SeatsLoading.vue";
import { Mentorcast } from "@/types/interfaces";
import MentorcastHeroRating from "@/components/Ratings/MentorcastHeroRating.vue";
import MentorcastCertificates from "@/components/Mentorcast/MentorcastCertificates.vue";
import MentorcastAuditorium from "@/components/Mentorcast/MentorcastAuditorium.vue";
import Content from "@/components/Contents/Content.vue";
import ConversationTab from "@/components/Conversation/Conversation.vue";
export default Vue.extend({
  name: "MentorcastHeroTabs",
  components: {
    Content,
    // Comments,
    ConversationTab,
    Ratings,
    SeatsLoading,
    MentorcastHeroRating,
    MentorcastCertificates,
    MentorcastAuditorium
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    mentorcast: {
      type: Object as () => Mentorcast
    },
    profile: Object
  },
  data() {
    return {
      conversationKey: 0,
      isLaunchModalOpened: false,
      ratingTabs: [
        {
          name: (this as any).$t("mentorcastHero.tabAllLabel"),
          type: "all"
        },
        {
          name: (this as any).$t("mentorcastHero.tabRecentLabel"),
          type: "recent"
        }
      ]
    };
  },
  computed: {
    canAccessContentTab(): boolean {
      return (
        this.mentorcast.can_i_comment_it ||
        this.mentorcast.user.id == this.profile.id
      );
    }
  },
  mounted(): void {
    if (this.isLaunchModalOpened) {
      this.$bvModal.show("launch");
    }
  },
  methods: {
    getMentorcast() {
      this.$emit("getMentorcastData");
    },
    onCommentsTab() {
      this.conversationKey++;
      this.$store.dispatch(
        "mentorcast/SET_MENTORCAST_COMMENTS_COUNT",
        this.mentorcast.uuid
      );
    },
    onContentTab() {
      (this.$refs as any)["content-tab"].getMentorCastContent();
    }
  }
});
