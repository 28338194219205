
import Vue from "vue";

export default Vue.extend({
  name: "MessageDeliveryIcon",
  props: {
    delivered_at: String,
    seen_at: String,
    created_at: {}
  },
  computed: {
    getDeliveredIcon() {
      if (this.delivered_at && !this.seen_at) {
        return "doubleTick";
      } else if (this.delivered_at && this.seen_at) {
        return "doubleTickBlue";
      } else if (!this.created_at) {
        return "clock";
      } else {
        return "single-tick";
      }
    }
  }
});
