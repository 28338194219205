
import Vue from "vue";
import ShareDropdown from "@/components/ShareDropdown.vue";

export default Vue.extend({
  name: "MentorImageModal",
  components: {
    ShareDropdown
  },
  props: {
    mentorcast: Object
  },
  data() {
    return {};
  }
});
