
import Vue from "vue";
import { Mentorcast } from "@/types/interfaces";
import ParticipantsModal from "@/components/Modals/ParticipantsModal.vue";
import BroadCastModal from "@/components/Modals/BroadCastModal.vue";
import Api from "@/services/Api";
import moment from "moment";

export default Vue.extend({
  name: "AuditoriumInfo",
  components: {
    ParticipantsModal,
    BroadCastModal
  },
  props: {
    mentorcast: {
      type: Object as () => Mentorcast
    },
    profile: Object
  },
  data() {
    return {
      mentorcastDetails: {
        platform: {
          label: (this as any).$t(
            "mentorcastHero.auditoriumMentorcastPlatformLabel"
          ),
          icon: "video-camera",
          replace: {
            key: "Hosted by Mentorice",
            value: "Mentorice"
          }
        },
        certificate_value: {
          label: (this as any).$t(
            "mentorcastHero.auditoriumMentorcastCertificatesLabel"
          ),
          icon: "certificate"
        },
        duration: {
          label: (this as any).$t(
            "mentorcastHero.auditoriumMentorcastEstDurationLabel"
          ),
          icon: "time-circle",
          suffix: "'"
        },
        isRatingEnabled: {
          label: (this as any).$t(
            "mentorcastHero.auditoriumMentorcastRatingsLabel"
          ),
          icon: "star-block"
        }
      },
      mentorcastSeatDetails: {
        max_seats: {
          label: (this as any).$t(
            "mentorcastHero.auditoriumMentorcastTotalSeatsLabel"
          ),
          icon: "seat-block"
        },
        upgraded_seats: {
          label: (this as any).$t(
            "mentorcastHero.auditoriumMentorcastUpgradedSeatsLabel"
          ),
          icon: "upgraded-seat"
        },
        attending_seats: {
          label: (this as any).$t(
            "mentorcastHero.auditoriumMentorcastReservedSeatsLabel"
          ),
          icon: "seat-checked"
        },
        available_seats: {
          label: (this as any).$t(
            "mentorcastHero.auditoriumMentorcastAvailableSeatsLabel"
          ),
          icon: "seat-available"
        }
      },
      guestUser: {
        id: 0,
        email: "",
        message: ""
      } as any,
      guestMessageSending: false
    };
  },
  computed: {
    isAvailableToLanch(): any {
      const startDate = this.mentorcast.start_date;
      const startTime = this.mentorcast.start_time;
      const currentDateTime = moment();
      const startDateTime = startDate + " " + startTime;
      return currentDateTime > moment(startDateTime).subtract(30, "minutes");
    }
  },
  methods: {
    profileLink(data: any): string {
      if (data.type == "mentee") {
        return `/mentorees/${data.uuid}`;
      }
      return `/mentors/${data.uuid}`;
    },

    onClickMentorName(data: any) {
      if (this.profile?.id) {
        this.$router.push(this.profileLink(data));
      } else {
        this.$bvModal.show("login");
      }
    },

    openParticipantModal() {
      //@ts-ignore
      this.$refs["participant-modal"].getParticipants();
      this.$bvModal.show("participants");
    },
    openGuestMessageModal({ id, email }: { id: number; email: string }) {
      this.guestUser.email = email;
      this.guestUser.id = id;
      this.guestUser.message = "";
      this.$bvModal.show("guest-message");
    },
    openBroadCastModal(recipients: Array<string>) {
      //@ts-ignore
      this.$refs["broadcast-modal"].setRecipients(recipients);
      this.$bvModal.show("broadcast-modal");
    },
    async sendGuestMessage() {
      if (!this.guestUser.message || this.guestUser.message.trim() === "") {
        (this as any).$alertify.error("Enter message");
        return;
      }

      try {
        this.guestMessageSending = true;
        await Api().post("/chat/guest-message", {
          user_id: this.guestUser.id,
          message: this.guestUser.message
        });
        this.guestUser = {};
        this.guestMessageSending = false;
        this.$bvModal.hide("guest-message");
        (this as any).$alertify.success("Message sent successfully");
      } catch (errors) {
        this.guestMessageSending = false;
        if (errors && errors.response) {
          console.log(errors.response.data.errors);
          if (errors.response.statue === 422) {
            for (const e of errors.response.data.errors) {
              e.map((item: string) => (this as any).$alertify.error(item));
            }
          }
        } else {
          console.error(errors);
        }
      }
    },
    onJoin() {
      console.log("onJoin", this.mentorcast);
      this.$emit("launchModalOpened");
      this.$bvModal.show("launch");
    },
    onReserveSeat() {
      this.$emit("reserveSeat");
    }
  }
});
