
import Vue from "vue";
import ChatContainer from "@/components/ChatContainer.vue";

export default Vue.extend({
  name: "ChatModal",
  components: { ChatContainer },
  props: {
    user: Object,
    isOpenedInPopup: Boolean,
    id: {
      type: String,
      default: "chat-modal"
    }
  },
  data() {
    return {
      isLoading: false
    };
  },
  computed: {},
  created: function(): void {
    this.$store.dispatch("helper/GET_DEFAULT_PLATFORM_CURRENCY");
  },
  methods: {
    closeModal() {
      this.$bvModal.hide(this.id);
    }
  }
});
