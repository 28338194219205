
import Vue from "vue";

interface Certificate {
  title: string;
  file: string | Blob;
  type: string;
}

export default Vue.extend({
  name: "AddCertificateModal",
  data() {
    return {
      maxTitleChars: 70,
      data: {
        title: "",
        file: "" as string | Blob,
        type: "custom"
      } as Certificate,
      errors: {} as any,
      processing: false as boolean
    };
  },
  methods: {
    async addCertificate() {
      try {
        this.errors = {};
        this.processing = true;
        await this.$store.dispatch("program/ADD_CERTIFICATES", {
          data: this.buildData(),
          programId: this.$route.params.id
        });
        this.resetForm();
        this.processing = false;
        (this.$refs["add-certificate-modal"] as any).hide();
      } catch (error) {
        this.processing = false;
        if (error.response && error.response.status === 422) {
          this.errors = error.response.data.errors;
        } else {
          console.error("An error occurred:", error.message);
        }
      }
    },
    handleFileUpload(event: Event): void {
      const fileInput = event.target as HTMLInputElement;
      const file = fileInput.files?.[0] as File | undefined;
      if (file) {
        try {
          this.data.file = file;
        } catch (error) {
          console.error("Error creating object URL:", error);
        }
      } else {
        console.error("No file selected.");
      }
    },
    buildData(): FormData {
      const formData = new FormData();
      for (const key in this.data) {
        const value = this.data[key as keyof Certificate];
        formData.append(key, value as string | Blob);
      }
      return formData;
    },
    hasError(type: string): any {
      if (type in this.errors && this.errors[type][0].length > 0) {
        return { error: this.errors[type][0] };
      }
      return null;
    },
    resetForm() {
      this.data = {
        title: "",
        file: "",
        type: "custom"
      };
    }
  }
});
