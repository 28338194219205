var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"seats-loading-data"},[_c('div',{staticClass:"active auditorium_preloader"},[_c('svg',{staticStyle:{"margin":"auto","background":"rgb(241 242 243 / 0%)","display":"block"},attrs:{"xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","viewBox":"0 0 100 100","preserveAspectRatio":"xMidYMid"}},[_c('path',{attrs:{"d":"M14.1,7.5l8.1,0v14.7c0,0.6,0,1.2,0.1,1.7c-0.1-0.6-0.1-1.2-0.1-1.7V7.5c0,0,63.6,0,63.6,0h-8.1 c0,0,0,14.7,0,14.7c0,7-4.6,13.3-11.7,17.1c-4.2,2.3-6.8,5.9-6.8,9.8v1.9c0,3.9,2.5,7.6,6.8,9.8c7.1,3.8,11.7,10,11.7,17.1 c0,0,0,14.7,0,14.7h8.1h-8.1H50h18V82.3c0-3.8-2.6-7.4-7-9.8c-0.1,0-0.1-0.1-0.2-0.1c-6.6-3.6-14.9-3.6-21.5,0 c-0.1,0-0.1,0.1-0.2,0.1c-4.5,2.4-7,6-7,9.8l0,10.2h18c0,0-18,0-18,0H14.1l8.1,0V77.8c0-7,4.6-13.3,11.7-17.1 c4.2-2.3,6.8-5.9,6.8-9.8v-1.9c0-3.9-2.5-7.6-6.8-9.8c-6.5-3.5-10.9-9-11.6-15.3l9.9,0c0.7,3.1,3,5.9,6.8,8c5.7,3,9.5,7.7,10.9,12.9 c1.4-5.2,5.3-9.9,10.9-12.9c3.8-2,6.2-4.8,6.8-8H32.2","fill":"none","stroke":"currentColor","stroke-width":"3","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('animate',{attrs:{"attributeName":"stroke-dasharray","keyTimes":"0;1","values":"480 100;480 110","dur":"3.2258064516129035s","repeatCount":"indefinite"}}),_c('animate',{attrs:{"attributeName":"stroke-dashoffset","keyTimes":"0;1","values":"0;-1180","dur":"3.2258064516129035s","repeatCount":"indefinite"}})])])]),_c('b-row',{staticClass:"justify-content-md-center flex-nowrap overflow-auto pl-8 pl-md-0 seats-container",attrs:{"align-h":"start","no-gutters":""}},_vm._l((12),function(j){return _c('b-col',{key:j,staticClass:"d-flex flex-column align-items-center justify-content-between px-4 px-lg-6",style:([
          j - 1 < 12 / 2
            ? { marginTop: 4 * j * (12 - j) + 'px' }
            : {
                marginTop: 4 * (12 - (j - 1)) * (12 - 1 - (12 - j)) + 'px'
              }
        ]),attrs:{"cols":"auto"}},[_c('div',_vm._l((4),function(i){return _c('b-row',{key:i,staticClass:"position-relative py-3 py-lg-4",attrs:{"align-v":"center","no-gutters":""}},[_c('div',{staticClass:"seat opacity-50"},[_c('inline-svg',{attrs:{"src":require(`@/assets/icons/seat.svg`),"fill":"#6C849C","height":"30","width":"30"}})],1)])}),1)])}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }