
import Vue from "vue";

export default Vue.extend({
  name: "CustomizeSeatTooltip",
  props: {
    target: String,
    seatNumber: String,
    seatData: Object,
    seatColors: Array,
    usedColors: Array
  },
  data() {
    return {
      data: {
        title: "",
        price: "",
        color: "",
        row: "",
        column: "",
        is_upgrade_full_row: false
      } as any,
      submitBtnLoading: false,
      maxTitleChars: 25,
      isFullRowUpgrade: false,
      isEditable: false,
      OpenIndicator: {
        render: (createElement: any) =>
          createElement("inline-svg", {
            attrs: {
              src: require(`@/assets/icons/arrow.svg`)
            }
          })
      }
    };
  },

  created(): void {
    const { color, title, price } = this.seatData;
    this.data.title = title;
    this.data.color = color;
    this.data.price = price;
    this.data.row = this.seatData.number.r;
    this.data.column = this.seatData.number.g;
    this.isEditable = !this.seatData.color;
  },
  methods: {
    onClose() {
      // @ts-ignore
      this.$refs["customize-seat-tooltip"].$emit("close");
    },
    upgradeSeat() {
      if (this.isEditable) {
        this.onSubmit();
      } else {
        this.isEditable = !this.isEditable;
      }
    },
    onSubmit() {
      this.submitBtnLoading = true;
      this.$store
        .dispatch("mentorcast/UPGRADE_MENTORCAST_SEAT", {
          id: this.$route.params.id,
          data: this.data
        })
        .then(() => {
          this.submitBtnLoading = false;
          this.isEditable = !this.isEditable;
          this.$emit("refresh", this.data);
        })
        .catch(error => {
          this.submitBtnLoading = false;
          if (error.response.status === 422) {
            (this as any).$alertify.error(error.response.data.errors[0]);
          }
        });
    },
    getColorLabel() {
      const index = this.seatColors.findIndex(
        e => (e as any).value == this.data.color
      );
      if (index > -1) {
        return (this as any).seatColors[index].label;
      }
      return null;
    },
    onlyNumber(event: any) {
      const ch = String.fromCharCode(event.which);
      if (!/[0-9]/.test(ch)) {
        event.preventDefault();
      }
    }
  }
});
