
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "DiscountModal",
  data() {
    return {
      account: "paypal",
      isAccountChosen: false,
      data: {
        value: null
      },
      errors: {} as any,
      submitBtnLoading: false,
      i_agree_1: false,
      i_agree_2: false
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile",
      discount: "discount/getDiscount",
      paymentTermsLink: "helper/getPaymentTermsLink"
    })
  },
  mounted() {
    this.getDiscount();
  },
  methods: {
    getDiscount() {
      this.$store
        .dispatch("discount/GET_DISCOUNT", {
          id: this.$route.params.id
        })
        .then(() => {
          this.data.value = this.discount?.value;
        });
    },
    onSubmit() {
      this.errors = {};
      this.submitBtnLoading = true;

      this.$store
        .dispatch("discount/CREATE_DISCOUNT", {
          id: this.$route.params.id,
          data: this.data
        })
        .then(message => {
          this.submitBtnLoading = false;
          // @ts-ignore
          this.$alertify.success(message);
          this.getDiscount();
          // @ts-ignore
          this.$bvModal.hide("discount");
          this.$emit("created");
        })
        .catch(error => {
          this.submitBtnLoading = false;

          if (error?.response?.status === 422) {
            this.errors = error.response.data.errors;
          }

          if (error?.response?.status === 406) {
            //@ts-ignore
            this.$alertify.error(error.response.data.message);
          }
        });
    },
    hasError(type: string) {
      if (type in this.errors && this.errors[type][0].length > 0) {
        return { error: this.errors[type][0] };
      }
      return null;
    },
    copySessionUrl(event: any) {
      const clipboardData =
        event?.clipboardData ||
        //@ts-ignore
        window?.clipboardData ||
        event?.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(this.discount.discount_code);
    }
  }
});
