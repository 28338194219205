
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "ApplyDiscountModal",
  props: ["seat"],
  data() {
    return {
      account: "paypal",
      isAccountChosen: false,
      data: {
        discount_code: ""
      },
      errors: {} as any,
      submitBtnLoading: false
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    })
  },
  methods: {
    onSubmit() {
      this.errors = {};
      this.submitBtnLoading = true;

      this.$store
        .dispatch("discount/CHECK_DISCOUNT", {
          id: this.$route.params.id,
          data: { ...this.data, ...this.seat }
        })
        .then(data => {
          this.submitBtnLoading = false;

          this.$emit("update", data.data);

          // @ts-ignore
          this.$alertify.success(data.message);
          // @ts-ignore
          this.$bvModal.hide("apply-discount");
        })
        .catch(error => {
          this.submitBtnLoading = false;

          if (error?.response?.status === 422) {
            this.errors = error.response.data.errors;
          }

          if (error?.response?.status === 406) {
            //@ts-ignore
            this.$alertify.error(error.response.data.message);
          }
        });
    },
    hasError(type: string) {
      if (type in this.errors && this.errors[type][0].length > 0) {
        return { error: this.errors[type][0] };
      }
      return null;
    },
    copySessionUrl(event: any) {
      const clipboardData =
        event?.clipboardData ||
        //@ts-ignore
        window?.clipboardData ||
        event?.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(this.data.discount_code);
    }
  }
});
