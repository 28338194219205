
import Vue from "vue";
import TermsAndConditionModal from "@/components/Modals/TermsAndConditionModal.vue";

export default Vue.extend({
  name: "LoginModal",
  components: { TermsAndConditionModal },
  data() {
    return {
      loading: false,
      data: {
        email: "",
        password: ""
      },
      errors: {} as any,
      passwordFieldType: "password",
      socialIcons: [
        { name: "google", link: "", color: "#1DA1F2", label: "Google" },
        { name: "facebook", link: "", color: "#1DA1F2", label: "Facebook" }
        // { name: "linkedin", link: "", color: "#1DA1F2", label: "Linkedin" }
      ],
      selectedProvider: ""
    };
  },
  methods: {
    switchPasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    onSubmit() {
      if (this.data.email === "" || this.data.password === "") return;
      this.loading = true;

      this.$store
        .dispatch("auth/LOGIN_USER", this.data)
        .then(() => {
          this.loading = false;
          // @ts-ignore
          this.$alertify.success("Login successful");

          this.$emit("update");
          this.$bvModal.hide("login");
        })
        .catch(error => {
          this.loading = false;
          if (error.response.status === 422) {
            for (const e of error.response.data.errors) {
              // @ts-ignore
              this.$alertify.error(e);
            }
          }
        });
    },
    doSocialLogin() {
      const provider = this.selectedProvider;
      if (!provider) {
        return;
      }
      this.$bvModal.hide("terms-and-condition");

      (this as any).$auth
        .authenticate(provider)
        .then((response: unknown) => {
          this.$store
            .dispatch("auth/SOCIAL_LOGIN", { provider, response })
            .then(() => {
              (this as any).$alertify.success(
                `Login Successfully Using ${provider.toLocaleUpperCase()}`
              );
              this.$emit("update");
              this.$bvModal.hide("login");
            })
            .catch((error: any) => {
              if (error.response.status === 422) {
                // @ts-ignore
                this.$alertify.error(error.response.data.errors[0]);
              }
            });
        })
        .catch((error: any) => {
          // @ts-ignore
          this.$alertify.error(error.response.data.message);
        });
    },
    onSocialLogin(provider: string) {
      this.selectedProvider = provider;
      this.$bvModal.show("terms-and-condition");
    },
    onSignup() {
      localStorage.setItem("intendedRoute", this.$route.path);
      this.$router.push("/register");
    },
    hasError(type: string) {
      if (type in this.errors && this.errors[type][0].length > 0) {
        return { error: this.errors[type][0] };
      }
      return null;
    }
  }
});
